import React from 'react';
import './Home.css';

const Home = () => {
    return (
        <div className="home-container">
            <div className="section-intro">
                <div className="section-intro-content">
                    <img src="https://cdn.ariaalessia.org/23_76d1b0cee1.jpeg" alt="Aria Alessia Research Foundation" />
                    <div className="intro-text">
                        The Aria Alessia Research Foundation is dedicated to cutting-edge
                        research and innovative educational programs. Our mission is to foster
                        an environment where pioneering ideas and technological advancements thrive,
                        driving positiive change in society. <br/> <br/>
                        Established to bridge the gap between technology and human behavior, 
                        the Foundation supports high-quality, impartial research and nurtures
                        a diverse, dynamic scientific community. We are committed toenhancing
                        public understanding and engagement with science, ensuring that our work 
                        contributes to a healthier, more informed world.<br/> <br/>

                        As a hub for transformative research and collaboration, the Aria Alessia 
                        Research Foundation champions the integration of interdisciplinary approaches
                        to solve complex problems, empowering the next generation of researchers and
                        practitioners.
                    </div>
                    <hr/>
                </div>
            </div>
            <div className="parallax section1"></div>
            <div className="section2">
                <div className="section2-content">
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <h2><span className="capital">Find the strength to <br/></span>
                    <span className="capital">transform the world. </span></h2>
                    <br/><br/>
                    <p className="desc">At Aria Alessia Research Foundation, we ride the waves of knowledge, 
                        exploring the realms of computer science, integrative behavioral informatics, 
                        applied behavior analysis, and mental health to illuminate the path to a 
                        brighter future.</p>
                    <br/>

                   
                </div>
            </div>

            <div className="rectangles-grid">
               
                <div className="rectangle1">
                    <h3>Research</h3>
                    <p>Our research is focused on developing innovative solutions to complex problems. 
                        We are committed to advancing the frontiers of knowledge and driving positive change 
                        in society.</p>
                </div>
                <div className="rectangle2">
                    <h3>Education</h3>
                    <p>Our educational programs are designed to inspire and empower the next generation of 
                        researchers and practitioners. We are dedicated to fostering a diverse, dynamic 
                        scientific community.</p>
                </div>
                <div className="rectangle3">
                    <h3>Outreach</h3>
                    <p>Our outreach initiatives are aimed at enhancing public understanding and engagement 
                        with science. We are committed to ensuring that our work contributes to a healthier, 
                        more informed world.</p>
                </div>

                <div className="rectangle4">
                    <h3>Collaboration</h3>
                    <p>Our collaborative approach brings together experts from diverse disciplines to solve 
                        complex problems. We are dedicated to fostering an environment where pioneering ideas 
                        and technological advancements thrive.</p>
                </div>
                <div className="rectangle5">
                    <h3>Research</h3>
                    <p>Our research is focused on developing innovative solutions to complex problems. 
                        We are committed to advancing the frontiers of knowledge and driving positive change 
                        in society.</p>
                </div>
                <div className="rectangle6">
                    <h3>Education</h3>
                    <p>Our educational programs are designed to inspire and empower the next generation of 
                        researchers and practitioners. We are dedicated to fostering a diverse, dynamic 
                        scientific community.</p>
                </div>
                <div className="rectangle7">
                    <h3>Outreach</h3>
                    <p>Our outreach initiatives are aimed at enhancing public understanding and engagement 
                        with science. We are committed to ensuring that our work contributes to a healthier, 
                        more informed world.</p>
                </div>

                <div className="rectangle8">
                    <h3>Collaboration</h3>
                    <p>Our collaborative approach brings together experts from diverse disciplines to solve 
                        complex problems. We are dedicated to fostering an environment where pioneering ideas 
                        and technological advancements thrive.</p>
                </div>
            </div>
        </div>
    );
};

export default Home;
