import React, { useState } from 'react';
import './Nav.css';  // Ensure this path is correct

const Nav = () => {
    const [activeSection, setActiveSection] = useState('');

    const handleMenuClick = (section) => {
        setActiveSection(section);
    };

    const handleCloseClick = () => {
        setActiveSection('');
    };

    return (
        <div>
            <div className="nav-container">
                <div className="nav-logo-box">
                    <img src="/img/logo.png" alt="Logo" className="nav-logo" />
                </div>
                <div className="nav-logo-text-box">
                    <div className="nav-logo-text">
                        <span className="aria-alessia">ARIA ALESSIA</span> <span className="research-foundation">Research Foundation</span>
                    </div>
                </div>
                <div className="nav-search-box">
                    <i className="fa fa-search nav-search"></i>
                </div>
                <div className="nav-links-box">
                    <div className="nav-links">
                        <div className="support"><i className="fa fa-heart"></i> Support Us</div>
                        <div className="request-info">Request Info</div>
                        <div className="log-in">Log In</div>
                        <div className="shop">Shop</div>
                    </div>
                </div>
            </div>
            <div className="second-menu-bar">
                <div className={`menu-item ${activeSection === 'research-areas' ? 'active' : ''}`} onClick={() => handleMenuClick('research-areas')}>
                    Research Areas and Institutes
                </div>
                <div className={`menu-item ${activeSection === 'latest' ? 'active' : ''}`} onClick={() => handleMenuClick('latest')}>
                    Latest
                </div>
                <div className={`menu-item ${activeSection === 'calendar' ? 'active' : ''}`} onClick={() => handleMenuClick('calendar')}>
                    Calendar
                </div>
                <div className={`menu-item ${activeSection === 'contact' ? 'active' : ''}`} onClick={() => handleMenuClick('contact')}>
                    Contact
                </div>
                <div className={`menu-item ${activeSection === 'menu' ? 'active' : ''}`} onClick={() => handleMenuClick('menu')}>
                    Menu
                </div>
            </div>
            <div className={`section ${activeSection === 'research-areas' ? 'active' : ''}`}>
                <span className="close-icon" onClick={handleCloseClick}><i className="fa fa-times "></i> <span className="close-text">Close</span></span>
                <h1>Research Areas and Institutes</h1>
                {/* Add your content here */}
            </div>
            <div className={`section ${activeSection === 'latest' ? 'active' : ''}`}>
                <span className="close-icon" onClick={handleCloseClick}><i className="fa fa-times "></i> <span className="close-text">Close</span></span>
            <h1>Latest</h1>
                {/* Add your content here */}
            </div>
            <div className={`section ${activeSection === 'calendar' ? 'active' : ''}`}>
                 <span className="close-icon" onClick={handleCloseClick}><i className="fa fa-times "></i> <span className="close-text">Close</span></span>
                <h1>Calendar</h1>
                {/* Add your content here */}
            </div>
            <div className={`section ${activeSection === 'contact' ? 'active' : ''}`}>
             <span className="close-icon" onClick={handleCloseClick}><i className="fa fa-times "></i> <span className="close-text">Close</span></span>
                <h1>Contact</h1>
                {/* Add your content here */}
            </div>
            <div className={`section ${activeSection === 'menu' ? 'active' : ''}`}>
                <span className="close-icon" onClick={handleCloseClick}><i className="fa fa-times "></i> <span className="close-text">Close</span></span>
                <h1>Menu</h1>
                {/* Add your content here */}
            </div>
        </div>
    );
}

export default Nav;
