import React from 'react';
import Nav from './Components/Nav/Nav.js';  // Make sure the path is correct
import Home from './Pages/Home/Home.js';  // Make sure the path is correct

function App() {
    return (
        <div className="App">
            <Nav />
            <Home />
            {/* other components */}
        </div>
    );
}

export default App;

